:root {
    --color-primary: rgba(2, 0, 36);
}
html {
    box-sizing: border-box;
    font-size: calc(1em + 1vw);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
html {
    color: var(--color-primary);
    font-family: "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

body {
    background: rgb(2, 0, 36);
    background: linear-gradient(
            90deg,
            var(--color-primary) 0%,
            rgba(36, 139, 186, 1) 68%,
            rgba(0, 212, 255, 1) 100%
    );
}

hr {
    background: linear-gradient(90deg, transparent 0%, var(--color-primary) 50%, transparent 100%);
    border: 0;
    color: var(--color-primary);
    height: 1px;
    margin: 0.5rem 0;
}

a {
    color: var(--color-primary);
}
.main {
    background-color: rgba(195, 213, 224, 0.4);
    border-radius: 4px;
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.6);
    border: 2px solid #232323;
    margin: 2rem;
    min-width: 360px;
    padding: 2rem;
    text-align: center;
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    position: relative;
    width: 100%;
}

.iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
